<template>
    <v-dialog v-model="dialog" width=900>
        <v-card>
            <v-form ref="formNuevaCirugia" v-model="formValid">

                <v-toolbar color="primary" dark dense>
                    <v-toolbar-title>Nueva solicitud</v-toolbar-title>
                    <v-spacer/>
                    <v-btn text @click="cancela">
                        <v-icon>mdi-close-box</v-icon>
                    </v-btn>
                </v-toolbar>

                <v-card-text>

                    <v-row class="mt-3" >

                        <v-col cols="12" sm="3" v-if='origen=="cirugia"'>
                            <v-text-field
                                append-icon="mdi-magnify"
                                @click:append="buscarPaciente"
                                v-model="datos.cip"
                                :rules="[
                                    value => !!value || 'Requerido.',
                                    value => value >= 10000010 || 'Este número no es válido',
                                ]"
                                label="Paciente"
                                type="number"
                                filled
                                rounded
                                dense

                            ></v-text-field>
                        </v-col>

                        <v-col cols="12" sm="9" v-if='origen=="cirugia"'>
                            <v-expand-transition>
                                <v-alert text type="warning" v-if="alertError">
                                    {{errorMsg}}
                                </v-alert>
                            </v-expand-transition>

                            <v-expand-transition>
                                <v-alert text type="success" v-if="alertPaciente">
                                    {{pacienteMsg}}
                                </v-alert>
                            </v-expand-transition>
                        </v-col>

                        <v-col cols="12" sm="6" >
                            <v-text-field
                                v-model="datos.padecimientoDescripcion"
                                label="Diagnóstico"
                                rounded
                                filled
                                dense
                                :disabled="!libreCirugias"
                                :rules="[v => !!v || 'Requerido.']"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6">
                            <v-text-field
                                v-model="datos.procedimientoDescripcion"
                                label="Procedimiento a realizar"
                                rounded
                                filled
                                dense
                                :disabled="!libreCirugias"
                                :rules="[v => !!v || 'Requerido.']"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="4">
                            <!-- Si no es del módulo de cirugía, carga fecha solicitada -->
                            <page-fecha
                                v-if='origen!="cirugia"'
                                label='Fecha solicitada'
                                :model.sync="datos.fechaSolicitadaFecha"
                                :dense="true"
                                :rules="[v => !!v || 'Requerido.']"
                            ></page-fecha>
                            <!-- Si es desde módulo de cirugía, carga fecha asignada -->
                            <page-fecha
                                v-if='origen=="cirugia"'
                                label='Fecha asignada'
                                :model.sync="datos.fechaAsignadaFecha"
                                :dense="true"
                                :rules="[v => !!v || 'Requerido.']"
                            ></page-fecha>
                        </v-col>
                        <v-col cols="12" sm="3">
                            <div class="d-flex flex-row ml-2" v-if='origen!="cirugia"'>
                                <v-text-field
                                    label="Hora solicitada"
                                    v-model="datos.fechaSolicitadaHora"
                                    dense
                                    rounded
                                    filled
                                    :rules="[
                                        value => !!value || 'Requerido.',
                                        value => /^(2[0-3]|[0-1]?[\d]):[0-5][\d]$/.test(value)||'Formato de hora no válido',
                                    ]"
                                    :disabled="!libreCirugias"
                                />
                                <v-menu
                                    v-if="libreCirugias"
                                    v-model="horaModal"
                                    bottom
                                    left
                                    :close-on-content-click="false"
                                >

                                    <template v-slot:activator="{ on }">
                                        <v-btn icon @click="horaModal=true" v-on="on"><v-icon>mdi-clock</v-icon></v-btn>
                                    </template>

                                    <v-time-picker
                                        v-model="datos.fechaSolicitadaHora"
                                        format="24hr"
                                        @change="horaModal=false"
                                    />
                                </v-menu>
                            </div>

                            <div class="d-flex flex-row ml-2" v-if='origen=="cirugia"'>
                                <v-text-field
                                    label="Hora asignada"
                                    v-model="datos.fechaAsignadaHora"
                                    dense
                                    rounded
                                    filled
                                    :rules="[
                                        value => !!value || 'Requerido.',
                                        value => /^(2[0-3]|[0-1]?[\d]):[0-5][\d]$/.test(value)||'Formato de hora no válido',
                                    ]"
                                    :disabled="!libreCirugias"
                                />
                                <v-menu
                                    v-if="libreCirugias"
                                    v-model="horaAsignadaModal"
                                    bottom
                                    left
                                    :close-on-content-click="false"
                                >

                                    <template v-slot:activator="{ on }">
                                        <v-btn icon @click="horaModal=true" v-on="on"><v-icon>mdi-clock</v-icon></v-btn>
                                    </template>

                                    <v-time-picker
                                        v-model="datos.fechaAsignadaHora"
                                        format="24hr"
                                        @change="horaModal=false"
                                    />
                                </v-menu>
                            </div>
                        </v-col>

                        <v-col cols="12" sm="3">
                            <v-text-field
                                v-model="datos.duracion"
                                label="Duración (en horas)"
                                rounded
                                filled
                                dense
                                type="number"
                                :rules="[
                                    v => !!v || 'Requerido.',
                                    v => v >= 1 || 'Este número no es válido',
                                ]"
                                :disabled="!libreCirugias"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="2">
                            <v-checkbox
                                v-model="datos.urgencia"
                                label="Urgencia"
                            ></v-checkbox>
                        </v-col>
                        <v-col cols="12" sm="4">
                            <div class="ml-3">
                                Riesgo quirúrgico
                                <v-rating
                                    :disabled="!libreCirugias"
                                    v-model="datos.riesgo"
                                    :length="5"
                                    :rules="[v => !!v || 'Requerido.']"
                                ></v-rating>
                        </div>
                        </v-col>
                        <v-col cols="12" sm="8">
                            <select-personal
                                label="Cirujano"
                                :model.sync="datos.cirujano"
                                :rules="[v => !!v || 'Requerido.']"
                            ></select-personal>
                        </v-col>
                        <v-col cols="12" sm="6">
                            <v-textarea
                                class="mr-1"
                                label="Solicitudes especiales (instrumental, equipo)"
                                v-model="datos.solicitudes"
                                rounded
                                filled
                                dense
                                :disabled="!libreCirugias"
                            ></v-textarea>
                        </v-col>
                        <v-col cols="12" sm="6">
                            <v-textarea
                                class="ml-1"
                                label="Anotaciones adicionales"
                                v-model="datos.observaciones"
                                rounded
                                filled
                                dense
                                :disabled="!libreCirugias"
                            ></v-textarea>
                        </v-col>
                    </v-row>
                </v-card-text>

                <v-card-actions>
                    <v-spacer/>

                    <v-btn text color="error" @click="cancela">
                        Cancelar
                    </v-btn>
                    <v-btn color="success" @click="guarda" :disabled="!(formValid && libreCirugias)" :loading="loadingGuarda">
                        Guardar
                    </v-btn>
                </v-card-actions>
            </v-form>
            <busca-paciente
                :dialogBuscaPaciente.sync="dialogBuscaPaciente"
                :paciente.sync="datos.cip"
                :todos="true"
            ></busca-paciente>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    components:{
        'page-fecha':()=>import('@/components/com/SelectEditaFecha'),
        'select-personal':()=>import('@/components/com/SelectPersonal'),
        'busca-paciente':()=>import('@/components/hospital/buscaPaciente'),
    },
    props:{

        dialogModal: Boolean,
        paciente: Number,
        cirugia: Object,

        internamiento:Number,
        consulta: Number
    },

    data:()=>({
        formValid:false,
        datos:{},

        horaModal:false,
        loadingGuarda:false,

        horaAsignadaModal:false,

        // Alertas de pacientes
        alertPaciente:false,
        pacienteMsg:'',

        alertError:false,
        errorMsg:'',

        pacienteValido:false,
        libreCirugias:false,

        // Para la búsqueda de paciente
        dialogBuscaPaciente:false,
        datosPaciente:{},

        origen:'',

        statusCirugia:{
            1:{
                color:'purple',
                text:'Solicitada',
            },
            2:{
                color:'blue',
                text:'Agendada',
            },
            3:{
                color:'orange',
                text:'En sala',
            },
            4:{
                color:'orange',
                text:'Recuper',
            },
            5:{
                color:'green',
                text:'Realizada',
            },
            90:{
                color:'red',
                text:'Reagenda',
            },
            99:{
                color:'black',
                text:'Cancelada',
            },
        },
    }),

    methods:{
        buscarPaciente(){
            //buscarPaciente(){
            this.datos.cip = null
            this.dialogBuscaPaciente=true
        },
        async guarda(){
            this.loadingGuarda=true
            if (!this.datos.urgencia){
                this.datos.urgencia = false
            }
            if (!this.datos.riesgo){
                this.datos.riesgo = 1
            }
            if (this.internamiento){
                this.datos.internamiento = this.internamiento
            }
            try{
                let req = await this.$http({
                    url: '/cirugia/create',
                    method:'POST',
                    params:this.datos,
                })
                this.loadingGuarda=false
                if (req.data.estado == "true"){
                    this.$emit("guardado")
                    this.$swal.fire({
                        icon: 'success',
                        title: 'Actualizado',
                        text: 'Actualizado correctamente',
                    })
                    this.dialog=false
                } else {
                    this.$swal.fire({
                        icon: 'warning',
                        title: 'No se puede guardar',
                        text: 'Verifique los datos e intente de nuevo',
                    })
                }
            } catch(err){
                this.loadingGuarda=false
                this.$store.dispatch('security/revisaError',err)
            }
        },
        cancela(){
            this.dialog=false
        },

        async cargaCirugias(){
            try{
                let req = await this.$http({
                    url: '/cirugia/list',
                    method:'POST',
                    params:{paciente:this.datos.cip},
                })
                this.cirugias = req.data.root.filter(ci=>ci.status < 5)
                if (this.cirugias.length>0){
                    this.libreCirugias=false
                    let cadena = ''

                    this.cirugias.forEach(ciru => {
                        if(ciru.fechaAsignada){
                            cadena = cadena+'<div>'+ciru.fechaAsignada+' ('+this.statusCirugia[ciru.status].text+')</div>'
                        } else {
                            cadena = cadena+'<div>'+ciru.fechaSolicitada+' ('+this.statusCirugia[ciru.status].text+')</div>'
                        }
                    });

                    this.$swal.fire({
                        icon: 'info',
                        title: 'No se puede agendar',
                        html: '<div>Este paciente tiene citas activas (<b>'+this.cirugias.length+'</b>): '+
                        '<div>'+cadena+'</div>'
                        ,
                    })
                } else {
                    this.libreCirugias=true
                }
                //console.log(this.cirugias)
            } catch(err){
                this.$store.dispatch('security/revisaError',err)
            }
        },

        async revisaPaciente(){
            // si total es 1, pasar, si es 0, mostrar error
            //Hace una petición a pacientes/list y obtener el registro 0
            // De ahi nececitamos en root:
            // internado, debe ser 0
            // internamientoDatos es un objeto que tiene los datos del internamiento (areaNom y cama)
            // tipoRegistro, debe ser 2. Si es 1, notificar para hacer cambio, si es 3, es baja y debe de mostrar error
            // otrosFechaDefuncion, debe ser blanco o null

            this.cargaCirugias()
            this.pacienteValido=false
            try{
                let dp = await this.$http({
                    url:'/pacientes/list',
                    method:'GET',
                    params:{id:this.datos.cip},
                })
                // Si existe el paciente
                if (dp.data.total == 1){
                    this.cargaCirugias
                    let datos = dp.data.root[0]

                    // Buscar si tiene defuncion
                    if (datos.otrosFechaDefuncion){
                        this.alertPaciente=false
                        this.alertError = true
                        this.errorMsg= 'El paciente '+this.datos.cip+' está marcado como (defunción)'
                    }

                    // Buscar si tiene internamientos
                    /*
                    if (datos.internado){
                        this.alertPaciente=false
                        this.alertError = true
                        this.errorMsg= 'El paciente '+this.datos.cip+' tiene internamientos activos ('+datos.internadoDatos.cama+')'
                    }
                    */

                    // Si no tiene nada, muestra los datos del paciente
                    if (datos.otrosFechaDefuncion=='' && datos.internado == 0){
                        this.alertPaciente=true
                        this.alertError=false
                        this.pacienteMsg=datos.nombreCompleto+' ('+datos.fechaNacimiento+')'
                        this.pacienteValido = true
                    }

                } else {
                    this.alertPaciente=false
                    this.alertError = true
                    this.errorMsg= 'El paciente '+this.datos.cip+' no existe'

                }

            }catch(err){
                this.$store.dispatch('security/revisaError',err)
            }
        }
    },

    computed:{
        dialog:{
            get(){return this.dialogModal},
            set(val){this.$emit('update:dialogModal',val)}
        }
    },
    watch:{
        dialog(v){
            if (!v){
                this.$refs.formNuevaCirugia.reset()
            }
            if(v){
                this.alertPaciente=false
                this.alertError = false
                if (this.internamiento){
                    console.log('Cargando desde INTERNAMIENTO')
                    this.origen = 'interna'
                    this.libreCirugias=true
                }
                if (this.consulta){
                    console.log('Cargando desde CONSULTA')
                    this.origen = 'consulta'
                    this.libreCirugias=true
                }
                if (!this.consulta && !this.internamiento){
                    console.log('Cargando desde CIRUGIA')
                    this.origen = 'cirugia'
                    this.libreCirugias=false
                }
                if (this.cirugia){
                    console.log('Cargando DATOS DE CIRUGIA')
                    this.origen = 'cirugiaDatos'
                    this.libreCirugias=false
                }
            }
        },
        'datos.cip'(val){
            if (val && val >= 10000010){
                this.revisaPaciente()
            }
        },
    },
}
</script>

<style>

</style>